import React, {useState} from 'react'
import Star from "../../img/star.svg"
import { useNavigate } from 'react-router-dom'
import axios from 'axios'
function isMobile() {
    return window.innerWidth < 768;
}
const Advertisement = ({ companyInfo }) => {
    const navigate = useNavigate()
    function goToAdvertPage() {
        if (isMobile()) {
            axios.post("companyClickTrackerMobile.php", { id: companyInfo.id })
            .then(res => console.log("res"))
            .catch(error => 
                console.error("Error: " + error)
            );
            navigate(`/emekdas/${companyInfo.id}`);
        }
        else {
            axios.post("companyClickTracker.php", { id: companyInfo.id })
            .then(res => console.log("res"))
            .catch(error => 
                console.error("Error: " + error)
            );
            navigate(`/emekdas/${companyInfo.id}`);
        }
    }
    return (
        <div className='Advertisement' onClick={() => goToAdvertPage()} key={companyInfo.id}>
            <div className='adImg'>
                <img src={companyInfo.profilerImagePath} alt='image' />
            </div>
            <div className='bottomPart'>
                <div className='firmTitle'>
                    <span><b>{companyInfo.company_name}</b></span>
                </div>
                <div className='firmDesc'>
                    <span>{companyInfo.work_description.replace(/\\n/g, '\n')}</span>
                </div>
                <div className='starsCommentPrice'>
                    <div className='starsCommentPriceL'>
                        <div className='stars'>
                            <img src={Star} alt='star' />
                            <span>{companyInfo.stars === 0 ? 0 : companyInfo.stars}</span>
                        </div>
                        <div className='comments'>
                            <span>({companyInfo.comments === undefined ? 0 : companyInfo.comments.length})</span>
                        </div>
                    </div>
                    <div className='price'>
                        <span>{companyInfo.min_price} AZN</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Advertisement