import React, { useEffect, useState } from 'react'
import "./Popup.css"
import PopUpPhone from "../../img/popupPhone.svg"
import googleplay from "../../img/googlePlay.svg"
import appStore from "../../img/appStore.svg"
import closeX from "../../img/X.svg"
const Popup = () => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    var is_modal_show = sessionStorage.getItem('alreadyShow');
    if (is_modal_show !== 'alredy shown') {
      setVisible(true);
      sessionStorage.setItem('alreadyShow', 'alredy shown');
    }
  }, [])
  const closePopup = () => {
    setVisible(false);
  }
  return (
    <>
      {visible && <div className='Popup'>
        <img className='closeBtn' src={closeX} alt='close' onClick={() => closePopup()} />
        <div className='popupContent'>
          <div className='popupContentL'>
            <img src={PopUpPhone} alt='getapPhone' />
          </div>
          <div className='popupContentR'>
            <div className='popupContentRTop'>
              <span className='popupContentTitle'>Getapı Endir</span>
              <span>Xidmət almaq belə asan olmamışdı</span>
            </div>
            <div className='popupContentRBottom'>
              <a href="https://play.google.com/store/apps/details?id=com.getap&hl=en&gl=US" target="_blank" rel='tag'>
                <img src={googleplay} alt='play store' />
              </a>
              <a href="https://apps.apple.com/az/app/getap/id6446489020" target="_blank" rel='tag'>
                <img src={appStore} alt='app store' />
              </a>
            </div>
          </div>
        </div>
      </div>}
    </>
  )
}

export default Popup