import React from 'react'
import "./InfoPart.css"
const InfoPart = () => {
    return (
        <div className='infopart'>
            <div className='advertisementText'>
                <span>Necə işləyir</span>
            </div>
            <div className='privilages'>
                <div className='privilage'>
                    <div className='privilageIcon'>
                        <svg width="42" height="37" viewBox="0 0 42 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M23.9344 24.2796L25.6614 22.019L31.5028 26.4842L29.7759 28.7448L23.9344 24.2796Z" fill="#1997D6" />
                            <path d="M40.9011 34.5793L40.0491 35.693C39.4992 36.4144 38.467 36.5542 37.7458 35.9996L28.4829 28.9207L31.3316 25.1964L40.5945 32.2753C41.3157 32.8254 41.451 33.8579 40.9011 34.5793Z" fill="#1997D6" />
                            <path d="M26.9964 14.5918C26.9964 21.6351 21.2884 27.3442 14.2482 27.3442C7.20798 27.3442 1.5 21.6351 1.5 14.5918C1.5 7.54838 7.20798 1.83936 14.2482 1.83936C21.2884 1.83936 26.9964 7.54838 26.9964 14.5918Z" stroke="#1997D6" strokeWidth="3" />
                        </svg>

                    </div>
                    <div className='privilageTitle'>
                        <span><b>Ehtiyacın olan xidməti axtar</b></span>
                    </div>
                    <div className='privilageDescription'>
                        <span>Xidmət göstərən fərdi və korporativ əməkdaşlarımızdan uyğun olanı tapın.</span>
                    </div>
                </div>
                <div className='privilage'>
                    <div className='privilageIcon'>
                        <svg width="48" height="40" viewBox="0 0 48 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M5.27102 8.30264H27.5199C29.5939 8.30264 31.2909 9.99959 31.2909 12.0736V27.9118C31.2909 29.9859 29.5939 31.6829 27.5199 31.6829H14.0952L6.77942 37.3394V31.6829H5.27102C3.19697 31.6829 1.50003 29.9859 1.50003 27.9118V12.0736C1.50003 9.99959 3.19697 8.30264 5.27102 8.30264Z" stroke="#1997D6" strokeWidth="1.79373" />
                            <path d="M42.6584 1.33936H19.4742C17.313 1.33936 15.5447 3.10764 15.5447 5.26888V21.7729C15.5447 23.9341 17.313 25.7024 19.4742 25.7024H33.4633L41.0866 31.5967V25.7024H42.6584C44.8196 25.7024 46.5879 23.9341 46.5879 21.7729V5.26888C46.5879 3.10764 44.8196 1.33936 42.6584 1.33936Z" fill="white" stroke="#1997D6" strokeWidth="1.86914" />
                        </svg>

                    </div>
                    <div className='privilageTitle'>
                        <span><b>Xidmət göstərənlə əlaqə saxla</b></span>
                    </div>
                    <div className='privilageDescription'>
                        <span>Ətraflı məlumat və xidmətin qiymətini öyrənmək üçün Getap üzərindən əlaqə saxlayın.</span>
                    </div>
                </div>
                <div className='privilage'>
                    <div className='privilageIcon'>
                        <svg width="39" height="37" viewBox="0 0 39 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M38.4 18.6594C38.4 16.0994 36.4 14.7394 33.6 14.7394H25.5201C26.0801 12.5794 26.4 10.4994 26.4 8.73936C26.4 1.77936 24.48 0.339355 22.8 0.339355C21.68 0.339355 20.88 0.419355 19.76 1.13936C19.44 1.29936 19.28 1.61936 19.2 1.93936L18 8.41936C16.72 11.8594 13.44 14.8194 10.8 16.8194V33.9394C11.68 33.9394 12.8 34.4194 13.84 34.9794C15.12 35.6194 16.48 36.2594 18 36.2594H29.44C31.84 36.2594 33.6 34.3394 33.6 32.6594C33.6 32.3394 33.6 32.0194 33.5201 31.7794C35.0401 31.2194 36 29.9394 36 28.4994C36 27.7794 35.84 27.1394 35.6 26.6594C36.48 26.0194 37.44 24.9794 37.44 23.6994C37.44 23.0594 37.12 22.2594 36.64 21.6994C37.68 20.8994 38.4 19.7794 38.4 18.6594ZM35.92 18.6594C35.92 20.1794 34.32 20.3394 34.08 21.0594C33.84 21.8594 35.04 22.1794 35.04 23.6194C35.04 25.1394 33.2001 25.1394 32.9601 25.8594C32.64 26.7394 33.5201 27.0594 33.5201 28.4994V28.7394C33.2801 29.9394 31.4401 30.0194 31.1201 30.4994C30.8001 31.0594 31.2 31.3794 31.2 32.6594C31.2 33.3794 30.4 33.8594 29.44 33.8594H18C17.12 33.8594 16 33.3794 14.96 32.8194C14 32.3394 13.04 31.8594 12 31.6194V18.8994C14.96 16.6594 18.8 13.2194 20.3201 9.05936C20.3201 8.97935 20.32 8.89936 20.4 8.89936L21.5201 2.89936C21.9201 2.73936 22.24 2.73936 22.88 2.73936C23.12 2.73936 24.08 4.17936 24.08 8.73936C24.08 10.4994 23.76 12.4994 23.1201 14.7394H22.8C22.16 14.7394 21.6 15.2994 21.6 15.9394C21.6 16.5794 22.16 17.1394 22.8 17.1394H33.6C34.8 17.1394 35.92 17.6994 35.92 18.6594Z" fill="#1997D6" />
                            <path d="M9.60006 36.3393H2.40006C1.04006 36.3393 6.10352e-05 35.2993 6.10352e-05 33.9394V17.1393C6.10352e-05 15.7793 1.04006 14.7393 2.40006 14.7393H9.60006C10.9601 14.7393 12.0001 15.7793 12.0001 17.1393V33.9394C12.0001 35.2993 10.9601 36.3393 9.60006 36.3393ZM2.40006 17.1393V33.9394H9.60006V17.1393H2.40006Z" fill="#1997D6" />
                        </svg>
                    </div>
                    <div className='privilageTitle'>
                        <span><b>Xidməti qəbul et</b></span>
                    </div>
                    <div className='privilageDescription'>
                        <span>Xidmət vərənin göndərdiyi təklifi qəbul edərək, güvəncə altına alın</span>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InfoPart