import { atom } from "jotai"
import axios from 'axios'
import Swal from 'sweetalert2'
import companyData from './companiesdata.json';


const allCompaniesAtom = atom([...companyData])
const allRequests = atom([])
const allRequestsDone = atom([])
const currentChatName = atom("")
const currentChatId = atom("")
const currentChatImagePath = atom("")
const serviceValue = atom("99")
const serviceSearch = atom("")

const fetchAllCompaniesAtom = atom(
  (get) => get(allCompaniesAtom),
  async (_get, set) => {
    console.log(allCompaniesAtom)
  }
)

const changeServiceValue = atom(
  (get) => get(serviceValue),
  (_get, set, serValue) => {
    _get(serviceSearch, set(serviceSearch, ""))
    set(serviceValue, serValue)
  }
)
const changeServiceValueSearch = atom(
  (get) => get(serviceSearch),
  (_get, set, searchInfo) => {
    set(serviceSearch, searchInfo)
  }
)

const sendServiceReqA = atom(
  (get) => get(serviceSearch),
  (_get, set, service, phoneNum, startDate) => {
    axios.post('http://80.241.208.93:8094/request/saveWebRequests', {
      servce: service,
      date: startDate,
      phoneNum : phoneNum,
    })
      .then(res => {
        Swal.fire({
          title: 'Uğurlu',
          text: 'Sizin tələbiniz uğurla qeydə alındı.',
          icon: 'success',
        });
      })
      .catch(error => {
        Swal.fire({
          title: 'Uğursuz',
          text: "Xəta yarandı. Zəhmət olmasa, yenidən cəhd edin.",
          icon: 'error',
        });
      });
  }
)

const sendToUserRandomCodeAtom = atom(
  (get) => get(allCompaniesAtom),
  async (get, set, phoneNum, randomNumber) => {
    try {
      const response = await fetch(`/api/sendsms.asp?user=getap456_servis&password=getap456s&gsm=${phoneNum}&text=${randomNumber}`);
      
    } catch (error) {
      console.error('Error:', error);
    }
  }
)

const registrationAtom = atom(
  (get) => get(allCompaniesAtom),
  async (get, set, name, lastname, mobileNum, isCoffee) => {
    axios.post('http://80.241.208.93:8094/user/register', {
      name: name,
      lastname: lastname,
      mobileNum: "+" + mobileNum,
      isCoffee: isCoffee,
    })
      .then(res => {
        Swal.fire({
          title: 'Uğurlu',
          text: 'Siz uğurla qeydiyyatdan keçdiniz.',
          icon: 'success',
        });
      })
      .catch(error => {
        Swal.fire({
          title: 'Uğursuz',
          text: "Xəta yarandı. Zəhmət olmasa, yenidən cəhd edin.",
          icon: 'error',
        });
      });
  }
)

const LoginAtom = atom(
  (get) => get(allCompaniesAtom),
  async (get, set, phonenum) => {
    axios.post('http://80.241.208.93:8094/user/login', {
      mobileNum: "+" + phonenum
    }
    )
      .then(res => {
        localStorage.setItem('userToken', res.data.data.accessToken)
        localStorage.setItem('userId', res.data.data.id)
        localStorage.setItem('userName', res.data.data.name)
        localStorage.setItem('userSurname', res.data.data.lastname)
        localStorage.setItem('mobileNum', res.data.data.mobileNum)
        localStorage.setItem('isUser', true)
        Swal.fire({
          title: 'Uğurlu',
          text: 'Siz uğurla daxil oldunuz.',
          icon: 'success',
        });
        window.location.reload()
      })
      .catch(error => {
        Swal.fire({
          title: 'Uğursuz',
          text: "Xəta yarandı. Zəhmət olmasa, yenidən cəhd edin.",
          icon: 'error',
        });
      });
  }
)

const setVisitWorker = atom(
  (get) => get(allCompaniesAtom),
  async (get, set, id) => {
    axios.post('http://80.241.208.93:8094/worker/setVisit', {
      workerId: id,
    }).catch((error) => {
      console.log(error)
    });
  }
)
const setVisitMessages = atom(
  (get) => get(allCompaniesAtom),
  async (get, set, id) => {
    axios.post('http://80.241.208.93:8094/worker/setMessage', {
      workerId: id,
    }).then((res) => console.log(res)).catch((error) => {
      console.log(error)
    });
  }
)


const registrationServiceAtom = atom(
  (get) => get(allCompaniesAtom),
  async (get, set, nameSurname, phonenum, isCofee) => {
    axios.post('http://80.241.208.93:8094/worker/register', {
      nameSurname: nameSurname,
      mobileNum: phonenum,
      isCoffee: isCofee,
    }
    )
      .then(res => {
        Swal.fire({
          title: 'Uğurlu',
          text: 'Siz uğurla qeydiyyatdan keçdiniz.',
          icon: 'success',
        });
      })
      .catch(error => {
        Swal.fire({
          title: 'Uğursuz',
          text: "Xəta yarandı. Zəhmət olmasa, yenidən cəhd edin.",
          icon: 'error',
        });
      });
  }
)
const LoginServiceAtom = atom(
  (get) => get(allCompaniesAtom),
  async (get, set, phonenum) => {
    axios.post('http://80.241.208.93:8094/worker/login', {
      mobileNum: phonenum
    }
    )
      .then(res => {
        Swal.fire({
          title: 'Uğurlu',
          text: 'Siz uğurla daxil oldunuz.',
          icon: 'success',
        });
        window.location.reload()
      })
      .catch(error => {
        Swal.fire({
          title: 'Uğursuz',
          text: "Xəta yarandı. Zəhmət olmasa, yenidən cəhd edin.",
          icon: 'error',
        });
      });
  }
)

const getOwnRequestsA = atom(
  (get) => get(allRequests),
  async (get, set) => {
    const response = axios.get('http://80.241.208.93:8094/api/request/getOwnRequests', {
      headers: { "x-access-token": localStorage.getItem('userToken') },
    });
    set(allRequests, (await response))
  }
)

const getOwnRequestsADone = atom(
  (get) => get(allRequestsDone),
  async (get, set) => {
    const response = axios.get('http://80.241.208.93:8094/api/request/getUserHistory', {
      headers: { "x-access-token": localStorage.getItem('userToken') },
    });
    set(allRequestsDone, (await response))
  }
)


const setCurrentChatNameA = atom(
  (get) => get(currentChatName),
  async (_get, set, name) => {
    set(currentChatName, name)
  }
)
const setCurrentChatIdA = atom(
  (get) => get(currentChatId),
  async (_get, set, id) => {
    set(currentChatId, id)
  }
)
const setCurrentChatImagePathA = atom(
  (get) => get(currentChatImagePath),
  async (_get, set, imagepath) => {
    set(currentChatImagePath, imagepath)
  }
)




export { allCompaniesAtom, allRequests, serviceSearch,sendServiceReqA, changeServiceValueSearch, currentChatImagePath, changeServiceValue, allRequestsDone, serviceValue, setCurrentChatIdA, currentChatId, currentChatName, setCurrentChatNameA, setCurrentChatImagePathA, fetchAllCompaniesAtom, sendToUserRandomCodeAtom, registrationAtom, LoginAtom, setVisitWorker, setVisitMessages, registrationServiceAtom, LoginServiceAtom, getOwnRequestsA, getOwnRequestsADone};