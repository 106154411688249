import './App.css';
import React, { useEffect, useState } from 'react'
import { Routes, Route } from "react-router-dom";
import Header from './Components/Header/Header';
import Home from './Pages/Home';
import googleplay from "../src/img/googlePlay.svg"
import appStore from "../src/img/appStore.svg"
import closeX from "../src/img/X.svg"
import Footer from './Components/Footer/Footer';
import AdvertisementPage from './Pages/AdvertisementPage';
import ErrorPage from './Pages/ErrorPage';

function App() {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    var is_modal_showB = sessionStorage.getItem('alreadyShowB');
    if (is_modal_showB !== 'alredy shownB') {
      setVisible(true);
      sessionStorage.setItem('alreadyShowB', 'alredy shownB');
    }
  }, [])
  const closePopup = () => {
    setVisible(false);
  }
  return (
    <div className="App">
      <Header />
      <Routes>
        <Route
          path='/'
          element={<Home />}
        />
        <Route
          path='/emekdas/:id'
          element={<AdvertisementPage />}
        />
        <Route
          path='/*'
          element={<ErrorPage />}
        />
      </Routes>
      <Footer />
      {visible && <div className='popUpBottom'>
        <div className='popUpBottomCenter'>
          <span>Xidmət almaq belə asan olmamışdı</span>
          <a href="https://play.google.com/store/apps/details?id=com.getap&hl=en&gl=US" target="_blank" rel='tag'>
            <img src={googleplay} alt='play store' />
          </a>
          <a href="https://apps.apple.com/az/app/getap/id6446489020" target="_blank" rel='tag'>
            <img src={appStore} alt='app store' />
          </a>
        </div>
        <img className='closeBtn' src={closeX} alt='close' onClick={() => closePopup()} />
      </div>}
    </div>
  );
}

export default App;
