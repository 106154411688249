import React, { useEffect, useState } from 'react'
import "./PopupMobile.css"
import PopUpMobile from "../../img/popupMobile.svg"
import googleplay from "../../img/googleplayM.svg"
import appStore from "../../img/appStoreM.svg"
import closeX from "../../img/X.svg"
const PopupMobile = () => {
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    var is_modal_showM = sessionStorage.getItem('alreadyShowM');
    if (is_modal_showM !== 'alredy shownM') {
      setVisible(true);
      sessionStorage.setItem('alreadyShowM', 'alredy shownM');
    }
  }, [])
  const closePopup = () => {
    setVisible(false);
  }
  return (
    <>
      {visible && <div className='PopupM'>
        <img className='closeBtn' src={closeX} alt='close' onClick={() => closePopup()} />
        <div className='popupContentM'>
          <div className='popupContentLM'>
            <img src={PopUpMobile} alt='getapPhone' />
          </div>
          <div className='popupContentRM'>
            <div className='popupContentRTopM'>
              <span className='popupContentTitleM'>Getapı Endir</span>
            </div>
            <div className='popupContentRBottomM'>
              <img src={googleplay} alt='play store' />
              <img src={appStore} alt='app store' />
            </div>
          </div>
        </div>
      </div>}
    </>
  )
}

export default PopupMobile