const options = [
    {
        value: 99,
        label: 'Hamısı',
    },
    {
        value: 1,
        label: 'Təmizlik',
    },
    {
        value: 2,
        label: 'Daşınma',
    },
    {
        value: 3,
        label: 'Xalça Yuma',
    },
    {
        value: 4,
        label: 'Dayə',
    },
    {
        value: 5,
        label: 'Özəl Dərs',
    },
    {
        value: 6,
        label: 'Bağçılıq',
    },
    {
        value: 7,
        label: 'Dərmanlama',
    },
    {
        value: 8,
        label: 'Ev Heyvanları',
    },
    {
        value: 9,
        label: 'Ev Təmiri',
    },
    {
        value: 10,
        label: 'Komputer/telefon',
    },
    {
        value: 11,
        label: 'Avto Təmir',
    },
    {
        value: 12,
        label: 'Ev əşyası təmiri',
    },
    {
        value: 13,
        label: 'Tibb bacısı',
    },
    {
        value: 14,
        label: 'Masaj',
    },
    {
        value: 15,
        label: 'Xəstə Baxıcı',
    },
    {
        value: 16,
        label: 'Dizayn',
    },
    {
        value: 17,
        label: 'Dərzi',
    },
    {
        value: 18,
        label: 'Yemək',
    },
    {
        value: 19,
        label: 'Fotoqraf',
    },
    {
        value: 20,
        label: 'Ad günü',
    },
    {
        value: 21,
        label: 'Toy/Nişan',
    },
    {
        value: 22,
        label: 'İT',
    },
    {
        value: 23,
        label: 'Digər',
    },
]


export default options;