import React from 'react'
import "./InfoPartPhone.css"
import getapApp from "../../img/getapApp.svg"
import getapAppIcon from "../../img/getapAppIcon.svg"
import googleplay from "../../img/googlePlay.svg"
import appStore from "../../img/appStore.svg"
const InfoPartPhone = () => {
    return (
        <div className='InfoPartPhone'>
            <div className='InfoPartPhoneContent'>
                <div className='InfoPartPhoneL'>
                    <img src={getapApp} alt='getapApp' />
                </div>
                <div className='InfoPartPhoneR'>
                    <div className='appIcon'>
                        <img src={getapAppIcon} alt='getapAppIcon' />
                    </div>
                    <div className='appMidd'>
                        <div className='appTitle'>
                            <span>Daha çoxu Getap tətbiqində</span>
                        </div>
                        <div className='appDesc'>
                            <span>Mesajlar, Dəyərləndirmə, Sifariş vermək və daha çoxu Getap Tətbiqində</span>
                        </div>
                    </div>
                    <div className='appmob'>
                        <a href="https://play.google.com/store/apps/details?id=com.getap&hl=en&gl=US" target="_blank" rel='tag'>
                            <img src={googleplay} alt='play store' />
                        </a>
                        <a href="https://apps.apple.com/az/app/getap/id6446489020" target="_blank" rel='tag'>
                            <img src={appStore} alt='app store' />
                        </a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default InfoPartPhone