import React from 'react'
import "./Footer.css"
import { MdLocationOn, MdEmail } from "react-icons/md";
import { IoCall } from "react-icons/io5";
import Instagram from "../../img/instagram.svg";
import Facebook from "../../img/facebook.svg";
import TikTok from "../../img/tiktok.svg";
import LinkedIn from "../../img/linkedin.svg";
import YouTube from "../../img/youtube.svg";
import GetapLogoWhite from "../../img/getapLogoWhite.svg"


const Footer = () => {
    return (
        <div className='footer'>
            <div className='footerComponent'>
                <div className='topPart'>
                    <div className='getapIconWhite'>
                        <img className='LogoWhite' src={GetapLogoWhite} alt='logo' />
                    </div>
                    <div className='socialMediaPart1'>
                        <a href="https://www.instagram.com/getap.az/" target="_blank" rel='tag'>
                            <img className='socialMediaIcon' src={Instagram} alt="Instagram" />
                        </a>
                        <a href="https://m.facebook.com/" target="_blank" rel='tag'>
                            <img className='socialMediaIcon' src={Facebook} alt="Facebook" />
                        </a>
                        <a href="https://www.tiktok.com/@getap.az" target="_blank" rel='tag'>
                            <img className='socialMediaIcon' src={TikTok} alt="TikTok" />
                        </a>
                        <a href="https://apps.apple.com/az/app/getap/id6446489020" target="_blank" rel='tag'>
                            <img className='socialMediaIcon' src={LinkedIn} alt="AppStore" />
                        </a>
                        <a href="https://play.google.com/store/apps/details?id=com.getap&hl=en&gl=US" target="_blank" rel='tag'>
                            <img className='socialMediaIcon' src={YouTube} alt="PlayStore" />
                        </a>
                    </div>
                </div>
                <div className='downPart'>
                    <div className='downPartL'>
                        <div className='copyright'>
                            <span>© 2023 All Copyrights Reserved To Getap</span>
                        </div>
                        <div className='privacyPolicy'>
                            <span>Privacy Policy & Terms & Conditions</span>
                        </div>
                    </div>
                    <div className='downPartR'>
                        <div className='phonePart'>
                            <div className='footerImg'>
                                <IoCall color='white' size={25} />
                            </div>
                            <div className='columnPart'>
                                <span>Əlaqə nömrəsi: </span>
                                <span>+994 12 514 44 57</span>
                            </div>
                        </div>
                        <div className='emailPart'>
                            <div className='footerImg'>
                                <MdEmail color='white' size={25} />
                            </div>
                            <div className='columnPart'>
                                <span>Email: </span>
                                <span>info@getap.az</span>
                            </div>
                        </div>
                        <div className='loactionPart'>
                            <div className='footerImg'>
                                <MdLocationOn color='white' size={25} />
                            </div>
                            <div className='columnPart'>
                                <span>Ünvan: </span>
                                <span>Bakı, Islam Abışov, 104</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Footer