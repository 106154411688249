import React, { useState, useEffect, useRef } from 'react'
import "./Header.css"
import GetapLogo from "../../img/getapLogo.svg"
import SearchIcon from "../../img/searchIcon.svg"
import Swal from 'sweetalert2'
import axios from 'axios'
import { useNavigate } from 'react-router-dom'
import { useAtom } from 'jotai'
import { changeServiceValueSearch } from '../../Atoms'

const Header = () => {
    const navigate = useNavigate()
    const [loginSignup, setLoginSignup] = useState(false);
    const [searchValueHeader, setSearchValueHeader] = useAtom(changeServiceValueSearch)
    const popupRefSign = useRef(null);
    const [showProvideService, setShowProvideService] = useState(false)
    const [serviseNameSurname, setServiseNameSurname] = useState("")
    const [phoneCode, setPhoneCode] = useState("+99450")
    const [phoneNum, setPhoneNum] = useState("")
    const [serviceName, setServiceName] = useState("")
    const [isNameSurExist, setIsNameSurExist] = useState(true)
    const [isPhoneExist, setIsPhoneExist] = useState(true)
    const [isServiceExist, setIsServiceExist] = useState(true)

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    function setPhoneNumFunc(e) {
        if (e.target.value?.length < 10 && !isNaN(e.target.value.replaceAll('-', ''))) {
            var len = e.target.value?.length;
            if ((len === 3 || len === 6) && !phoneNum.endsWith('-')) {
                setPhoneNum(e.target.value + "-");
            }
            else if (len < 10) {
                setPhoneNum(e.target.value);
            }
        }
    }
    function sendServiceReq() {
        if (!serviseNameSurname) setIsNameSurExist(false)
        else setIsNameSurExist(true)
        if (!phoneNum) setIsPhoneExist(false)
        else setIsPhoneExist(true)
        if (!serviceName) setIsServiceExist(false)
        else setIsServiceExist(true)

        if (isNameSurExist && isPhoneExist && isServiceExist) {
            const fd = new FormData();
            fd.append('serviceName', serviceName)
            fd.append('personName', serviseNameSurname);
            fd.append('phoneNumber', phoneCode + "-" + phoneNum);
            axios.post('provideService.php', fd).then(res => {
              Swal.fire({
                title: 'Uğurlu',
                text: 'Ən qısa zamanda sizinlə əlaqə saxlayacağıq.',
                icon: 'success',
              });
            }).catch(error => {
              Swal.fire({
                title: 'Uğursuz',
                text: "Xəta yarandı. Zəhmət olmasa, yenidən cəhd edin.",
                icon: 'error',
              });
            });
        }
    }
    function closeServiceReq() {
        setShowProvideService(false)
        setServiseNameSurname("")
        setPhoneNum("")
        setServiceName("")
    }
    const handleClickOutside = (event) => {
        if (popupRefSign.current && !popupRefSign.current.contains(event.target) && event.target.className !== "showPopUpSignClass") {
            setLoginSignup(false);
        }
    };
    const handleClick = () => {
        setLoginSignup(!loginSignup);
    };
    const goToHome = () => {
        navigate("/");
    }
    return (
        <div className='header'>
            <div className='headerContent'>
                <div className='headerContentLeft'>
                    <div className='getapLogo' onClick={() => goToHome()}>
                        <img src={GetapLogo} alt='logo' />
                    </div>
                    {window.location.pathname === "/" && <div className='searchHeader'>
                        <img src={SearchIcon} alt='Search icon' />
                        <input placeholder='Xidmət axtar' value={searchValueHeader} onChange={e => setSearchValueHeader(e.target.value)} />
                        <button>Axtar</button>
                    </div>}
                </div>
                <div className='headerContentRight'>
                    <div className='loginSignupService'>
                        <button className='loginH' onClick={e => setShowProvideService(!showProvideService)}>Xidmət ver</button>
                    </div>
                </div>
            </div>
            {
                showProvideService &&
                <div className='showProvideService'>
                    <div className='showProvideServiceContent'>
                        <h2 className="form-header">Xidmət ver</h2>
                        <div className="form1">
                            <div className="form-input1">
                                <label>Ad Soyad</label>
                                <input className="inputs1" type="text" name="name" value={serviseNameSurname} onChange={e => setServiseNameSurname(e.target.value)} />
                                {isNameSurExist || !serviseNameSurname && <span className="needToFill needToFillNameSur">*Adınızı və Soyadınızı qeyd edin</span>}
                            </div>
                            <div className="form-input1">
                                <label>Telefon Nömrəsi</label>
                                <div className="form-input1Phone form-input1Phone1 ">
                                    <select name="operators" id="operator" className="selectPhNumber1" value={phoneCode} onChange={e => setPhoneCode(e.target.value)}>
                                        <option value="+99450">050</option>
                                        <option value="+99451">051</option>
                                        <option value="+99455">055</option>
                                        <option value="+99470">070</option>
                                        <option value="+99477">077</option>
                                        <option value="+99499">099</option>
                                        <option value="+99410">010</option>
                                    </select>
                                    <input type="phone" className="searchServiceİnput1 " placeholder="XXX-XX-XX" onChange={e => setPhoneNumFunc(e)} value={phoneNum} />
                                </div>
                                {isPhoneExist || !phoneNum && <span className="needToFill needToFillNum">*Nömrənizi qeyd edin</span>}
                            </div>
                            <div className="form-input1">
                                <label>Xidmət Göstərəcəyiniz Sahə</label>
                                <input className="inputs1" type="text" name="field" value={serviceName} onChange={e => setServiceName(e.target.value)} />
                                {isServiceExist || !serviceName && <span className="needToFill needToFillService1">*Xidmət göstərəcəyiniz sahəni qeyd edin</span>}
                            </div>
                            <div className="form-btn-container">
                                <button className="btn-green approve" onClick={e => sendServiceReq()}>Göndər</button>
                                <button className="btn-cancel" onClick={e => closeServiceReq()}>Ləğv Edin</button>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Header