import React, { useState } from 'react'
import "./SearchPart.css"
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import allIcon from "../../img/image_2023-07-28_09-51-49.png"
import clearIcon from "../../img/clearIcon.png"
import drawIcon from "../../img/image_2023-07-27_17-52-24.png"
import carpetIcon from "../../img/image_2023-07-27_17-49-56.png"
import babySitter from "../../img/image_2023-07-27_17-51-57.png"
import lessonsIcon from "../../img/image_2023-07-27_17-52-09.png"
import gardenIcon from "../../img/image_2023-07-27_17-50-40.png"
import bitIcon from "../../img/image_2023-07-27_17-50-06.png"
import petIcon from "../../img/image_2023-07-27_17-51-43.png"
import buildingIcon from "../../img/image_2023-07-27_17-50-31.png"
import comPhoneREpairIcon from "../../img/image_2023-07-27_17-50-13.png"
import carRepair from "../../img/image_2023-07-27_17-51-08.png"
import homeItemsIcon from "../../img/image_2023-07-27_17-50-51.png"
import docIcon from "../../img/image_2023-07-27_17-49-45.png"
import massageIcon from "../../img/image_2023-07-27_17-49-34.png"
import serviIcon from "../../img/image_2023-07-27_17-49-22.png"
import designIcon from "../../img/image_2023-07-27_17-50-22.png"
import tailorIcon from "../../img/image_2023-07-27_17-51-50.png"
import foodIcon from "../../img/image_2023-07-27_17-48-52.png"
import photograpIcon from "../../img/image_2023-07-27_17-51-34.png"
import weddingIcon from "../../img/image_2023-07-27_17-51-26.png"
import birthdayIcon from "../../img/image_2023-07-27_17-48-26.png"
import itIcon from "../../img/image_2023-07-27_17-51-00.png"
import othersIcon from "../../img/image_2023-07-27_17-51-19.png"
import axios from 'axios';
import Swal from 'sweetalert2'
import Carousel from 'react-elastic-carousel';
import { changeServiceValue } from '../../Atoms';
import { useAtom } from 'jotai';
import { sendServiceReqA } from "../../Atoms"
import options from '../../services';

const SearchPart = () => {
  const [startDate, setStartDate] = useState(new Date());
  const [phoneCode, setPhoneCode] = useState('+99450');
  const [phoneNum, setPhoneNum] = useState('');
  const [, setSerVale] = useAtom(changeServiceValue)
  const [, sendServiceRequestAtom] = useAtom(sendServiceReqA)
  const [service, setService] = useState("1")
  const carouselRef = React.useRef(null);
  const [enableAuto, setEnableAutoPlay] = useState(true);
  const [checkNum, setCheckNum] = useState(true)
  const onNextStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo(0);
    }
  };
  const onPrevStart = (currentItem, nextItem) => {
    if (currentItem.index === nextItem.index) {
      carouselRef.current.goTo(26);
    }
  };
  const handlePhoneChange = (e) => {
    if (e.target.value?.length < 10 && !isNaN(e.target.value.replaceAll('-', ''))) {
      var len = e.target.value?.length;
      if ((len === 3 || len === 6) && !phoneNum.endsWith('-')) {
        setPhoneNum(e.target.value + "-");
      }
      else if (len < 10) {
        setPhoneNum(e.target.value);
      }
    }
  };
  const breakPoints = [
    { width: 300, itemsToShow: 3 },
    { width: 500, itemsToShow: 5 },
    { width: 768, itemsToShow: 6 },
    { width: 1200, itemsToShow: 10 },
    { width: 1600, itemsToShow: 11 }
  ];

  const SendRequest = () => {
    if(phoneNum.length < 9) setCheckNum(false)
    else setCheckNum(true)
    let phonenum = phoneCode + phoneNum.replaceAll('-', '')
    if (phonenum.length === 13) {
      setPhoneNum('')
      setService('1')
      setStartDate(new Date())
      const targetOption = options.find(option => option.value == service);
      const fd = new FormData();
      fd.append('serviceName', targetOption.label)
      fd.append('serviceId', service);
      fd.append('phone', phonenum);
      fd.append('date', startDate);
      axios.post('serviceRequest.php', fd).then(res => {
        Swal.fire({
          title: 'Uğurlu',
          text: 'Ən qısa zamanda sizinlə əlaqə saxlayacağıq.',
          icon: 'success',
        });
      }).catch(error => {
        Swal.fire({
          title: 'Uğursuz',
          text: "Xəta yarandı. Zəhmət olmasa, yenidən cəhd edin.",
          icon: 'error',
        });
      });
    }
  }

  const handleMouseEnter = () => {
    console.log(false)
    setEnableAutoPlay(false);
  };

  const handleMouseLeave = () => {
    console.log(true)
    setEnableAutoPlay(true);
  };

  return (
    <div className='searchpart'>
      <div className='serviceRequestPart'>
        <span>Xidmət Tələbi Göndər</span>
        <div className='serviceRequest'>
          <div className='serviceType'>
            <label>Xidməti seçin</label>
            <select placeholder="Xidmətlər" className="searchServiceİnput" defaultValue={"1"} value={service} onChange={(e) => setService(e.target.value)} >
              <option value="99">Hamısı</option>
              <option value="1">Təmizlik</option>
              <option value="2">Daşınma</option>
              <option value="3">Xalça Yuma</option>
              <option value="4">Dayə</option>
              <option value="5">Özəl Dərs</option>
              <option value="6">Bağçılıq</option>
              <option value="7">Dərmanlama</option>
              <option value="8">Ev Heyvanları</option>
              <option value="9">Ev Təmiri</option>
              <option value="10">Komputer/telefon</option>
              <option value="11">Avto Təmir</option>
              <option value="12">Ev əşyası təmiri</option>
              <option value="13">Tibb bacısı</option>
              <option value="14">Masaj</option>
              <option value="15">Xəstə Baxıcı</option>
              <option value="16">Dizayn</option>
              <option value="17">Dərzi</option>
              <option value="18">Yemək</option>
              <option value="19">Fotoqraf</option>
              <option value="20">Ad günü</option>
              <option value="21">Toy/Nişan</option>
              <option value="22">İT</option>
              <option value="23">Digər</option>
            </select>
          </div>
          <div className='serviceData'>
            <label>Tarixi seçin</label>
            <DatePicker
              id='datePickerId'
              dateFormat="dd.MM.yyyy"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              yearItemNumber={true}
            />
          </div>
          <div className='serviceNumber'>
            <label>Nömrənizi qeyd edin</label>
            <div className="inputOfphoneNumber">
              <select id="operator" className="selectPhNumber" onChange={e => setPhoneCode(e.target.value)}>
                <option value="+99450">050</option>
                <option value="+99451">051</option>
                <option value="+99455">055</option>
                <option value="+99470">070</option>
                <option value="+99477">077</option>
                <option value="+99499">099</option>
                <option value="+99410">010</option>
              </select>
              <input
                type="tel"
                className="searchServiceİnputNumber"
                id="phone"
                name="phone"
                placeholder="XXX-XX-XX"
                value={phoneNum}
                onChange={handlePhoneChange}
                pattern="[0-9]{3}-[0-9]{2}-[0-9]{2}"
                required
              />
            </div>
            {checkNum || !phoneNum && <span className="needToFill needToFillNum">*Nömrənizi qeyd edin</span>}
          </div>
          <div className='serviceButton'>
            <button onClick={() => SendRequest()}>Davam et</button>
          </div>
        </div>
      </div>
      <div className='filters' onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <Carousel
          breakPoints={breakPoints}
          enableTilt={false}
          enableAutoPlay={enableAuto}
          autoPlaySpeed={2000}
          pagination={false}
          itemsToScroll={1}
          ref={carouselRef}
          onPrevStart={onPrevStart}
          onNextStart={onNextStart}
          onNextEnd={({ index }) => {
            clearTimeout(2000)
            if (index + 1 === 15) {
              if (carouselRef?.current?.goTo) {
                if (carouselRef?.current?.goTo) {
                  carouselRef.current.goTo(0)
                }
              }
            }
          }}
          disableArrowsOnEnd={false}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className='filter allIcon' onClick={() => setSerVale(99)}>
            <img src={allIcon} alt='icon' />
            <span onClick={() => setSerVale(99)}>Hamısı</span>
          </div>

          <div className='filter' onClick={() => setSerVale(1)}>
            <img src={clearIcon} alt='icon' />
            <span onClick={() => setSerVale(1)}>Təmizlik</span>
          </div>

          <div className='filter' onClick={() => setSerVale(2)}>
            <img src={drawIcon} alt='icon' />
            <span onClick={() => setSerVale(2)}>Daşınma</span>
          </div>

          <div className='filter' onClick={() => setSerVale(3)}>
            <img src={carpetIcon} alt='icon' />
            <span onClick={() => setSerVale(3)}>Xalça Yuma</span>
          </div>

          <div className='filter' onClick={() => setSerVale(4)}>
            <img src={babySitter} alt='icon' />
            <span onClick={() => setSerVale(4)}>Dayə</span>
          </div>

          <div className='filter' onClick={() => setSerVale(5)}>
            <img src={lessonsIcon} alt='icon' />
            <span onClick={() => setSerVale(5)}>Özəl Dərs</span>
          </div>

          <div className='filter' onClick={() => setSerVale(6)}>
            <img src={gardenIcon} alt='icon' />
            <span onClick={() => setSerVale(6)}>Bağçılıq</span>
          </div>

          <div className='filter' onClick={() => setSerVale(7)}>
            <img src={bitIcon} alt='icon' />
            <span onClick={() => setSerVale(7)}>Dərmanlama</span>
          </div>

          <div className='filter' onClick={() => setSerVale(8)}>
            <img src={petIcon} alt='icon' />
            <span onClick={() => setSerVale(8)}>Ev Heyvanları</span>
          </div>

          <div className='filter' onClick={() => setSerVale(9)}>
            <img src={buildingIcon} alt='icon' />
            <span onClick={() => setSerVale(9)}>Ev Təmiri</span>
          </div>

          <div className='filter' onClick={() => setSerVale(10)}>
            <img src={comPhoneREpairIcon} alt='icon' />
            <span onClick={() => setSerVale(10)}>Kompüter/Telefon</span>
          </div>

          <div className='filter' onClick={() => setSerVale(11)}>
            <img src={carRepair} alt='icon' />
            <span onClick={() => setSerVale(11)}>Avto Təmir</span>
          </div>

          <div className='filter' onClick={() => setSerVale(12)}>
            <img src={homeItemsIcon} alt='icon' />
            <span onClick={() => setSerVale(12)}>Ev əşyası təmiri</span>
          </div>

          <div className='filter' onClick={() => setSerVale(13)}>
            <img src={docIcon} alt='icon' />
            <span onClick={() => setSerVale(13)}>Tibb Bacısı</span>
          </div>

          <div className='filter' onClick={() => setSerVale(14)}>
            <img src={massageIcon} alt='icon' />
            <span onClick={() => setSerVale(14)}>Masaj</span>
          </div>

          <div className='filter' onClick={() => setSerVale(15)}>
            <img src={serviIcon} alt='icon' />
            <span onClick={() => setSerVale(15)}>Xəstə Baxıcı</span>
          </div>

          <div className='filter' onClick={() => setSerVale(16)}>
            <img src={designIcon} alt='icon' />
            <span onClick={() => setSerVale(16)}>Dizayn</span>
          </div>

          <div className='filter' onClick={() => setSerVale(17)}>
            <img src={tailorIcon} alt='icon' />
            <span onClick={() => setSerVale(17)}>Dərzi</span>
          </div>

          <div className='filter' onClick={() => setSerVale(18)}>
            <img src={foodIcon} alt='icon' />
            <span onClick={() => setSerVale(18)}>Yemək</span>
          </div>

          <div className='filter' onClick={() => setSerVale(19)}>
            <img src={photograpIcon} alt='icon' />
            <span onClick={() => setSerVale(19)}>Fotoqraf</span>
          </div>

          <div className='filter' onClick={() => setSerVale(20)}>
            <img src={birthdayIcon} alt='icon' />
            <span onClick={() => setSerVale(20)}>Ad günü</span>
          </div>

          <div className='filter' onClick={() => setSerVale(21)}>
            <img src={weddingIcon} alt='icon' />
            <span onClick={() => setSerVale(21)}>Toy/Nişan</span>
          </div>

          <div className='filter' onClick={() => setSerVale(22)}>
            <img src={itIcon} alt='icon' />
            <span onClick={() => setSerVale(22)}>İT</span>
          </div>

          <div className='filter' onClick={() => setSerVale(23)}>
            <img src={othersIcon} alt='icon' />
            <span onClick={() => setSerVale(23)}>Digər</span>
          </div>
        </Carousel>
      </div>
    </div>
  )
}

export default SearchPart