import React, { useEffect } from 'react'
import SearchPart from '../Components/SearchPart/SearchPart'
import Advertisements from '../Components/Advertisements/Advertisements'
import InfoPartPhone from '../Components/InfoPartPhone/InfoPartPhone'
import InfoPart from '../Components/InfoPart/InfoPart'
import Popup from '../Components/Popup/Popup'
import PopupMobile from '../Components/Popup/PopupMobile'
import { useAtom } from 'jotai'
import { allCompaniesAtom, fetchAllCompaniesAtom } from '../Atoms'

const Home = () => {
  useEffect(() => {
    window.scrollTo(0, 0)
    setAllCompanies()
  }, [])
  const [allCompanies, setAllCompanies] = useAtom(fetchAllCompaniesAtom)
  console.log(allCompanies)
  return (
    <div className='home'>
      <SearchPart />
      {allCompanies && <Advertisements allCompanies={allCompanies} />}
      <InfoPartPhone />
      <InfoPart />
      <Popup />
      <PopupMobile />
    </div>
  )
}

export default Home