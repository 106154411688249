import React, { useEffect, useState } from 'react'
import "./Advertisements.css"
import Advertisement from './Advertisement'
import { serviceSearch, serviceValue } from '../../Atoms'
import { useAtom } from 'jotai'

const Advertisements = ({ allCompanies }) => {
  const showAllCompanies = []
  const [serValue] = useAtom(serviceValue)
  const [searchValue] = useAtom(serviceSearch)
  return (
    <div className='Advertisements'>
      <div className='advertisementText'>
        <span>Əməkdaşlar</span>
      </div>
      {searchValue.length == 0 && serValue == "99" ?
        allCompanies.map(company => {
          return (<Advertisement companyInfo={company} />)

        }) :
        allCompanies.map(company => {
          if (company.service.toString() == serValue) {
            return (<Advertisement companyInfo={company} />)
          }
        })
      }
      {searchValue.length !== 0 &&
        allCompanies.map(company => {
          if (
            company.company_name.toUpperCase().includes(searchValue.toUpperCase()) ||
            company.work_description.toUpperCase().includes(searchValue.toUpperCase()) ||
            company.min_price.toString().includes(searchValue) ||
            company.stars.toString().includes(searchValue)) {
            return (<Advertisement companyInfo={company} />)
          }
        })
      }
    </div>
  )
}

export default Advertisements
