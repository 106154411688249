import React, { useEffect, useRef, useState } from 'react';
import "./AdvertPage.css"
import { IoCall } from "react-icons/io5";
import ImageGallery from 'react-image-gallery';
import { useParams } from 'react-router-dom';
import companyjson from "../../companiesdata.json"
import axios from 'axios';
function isMobile() {
    return window.innerWidth < 768; // Предполагаем, что устройство с шириной меньше 768 пикселей считаем мобильным
}

const AdvertPage = () => {
    const [mobile, setMobile] = useState(false);
    const { id } = useParams();
    const [openPhonePopUp, setOpenPhonePopUp] = useState(false)
    const popupRefSign = useRef(null);

    useEffect(() => {
        document.addEventListener('click', handleClickOutside);

        return () => {
            document.removeEventListener('click', handleClickOutside);
        };
    }, []);

    const handleClickOutside = (event) => {
        if (popupRefSign.current && event.target.className !== "makePhoneCall" && event.target.className !== "") {
            console.log(event.target.className)
            setOpenPhonePopUp(false);
        }
    };
    useEffect(() => {
        window.scrollTo(0, 0)
        console.log(companyjson[id - 1])
    }, []);
    useEffect(() => {
        function handleResize() {
            setMobile(isMobile());
        }

        // Проверяем размер окна при монтировании компонента
        handleResize();

        // Подписываемся на событие изменения размера окна
        window.addEventListener('resize', handleResize);

        // Отписываемся от события при размонтировании компонента
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const images = [];
    companyjson[id - 1]?.images.forEach(element => {
        let obj = {
            original: element,
            thumbnail: element,
        }
        images.push(obj)
    });
    const myRef = useRef(null);
    if (myRef.current) {
        let starsElement = document.querySelector('.starsA');
        starsElement.style.setProperty('--rating', companyjson[id - 1].stars);
    }
    const imageGalleryRef = useRef(null);

    const onClickHandler = () => {
        imageGalleryRef.current.fullScreen();
    };
    function OpenPhonePopUp() {
        console.log(id)
        if (isMobile()) {
            window.location.href = `tel:${companyjson[id - 1].phone}`;
            axios.post("callClickTrackerMobile.php", { id: id })
            .then(res => console.log("res"))
            .catch(error => 
                console.error("Error: " + error)
            );
        }
        else {
            setOpenPhonePopUp(!openPhonePopUp)
            axios.post("callClickTracker.php", { id: id })
            .then(res => console.log("res"))
            .catch(error => 
                console.error("Error: " + error)
            );
        }
    }
    return (
        <div className='AdvertPage'>
            <div className='AdvertPageContent'>
                <div className='AdvertPageContentL'>
                    <div className='AdvertPageCont1'>
                        <div className='AdvertPageContentL1'>
                            <img src={companyjson[id - 1].profilerImagePath} alt="company image" />
                        </div>
                        <div className='AdvertPageContentL2'>
                            <div className='AdvertPageContentL2Title'>
                                <span>{companyjson[id - 1].company_name}</span>
                            </div>
                            <div className='AdvertPageContentL2Desc'>
                                <span>{companyjson[id - 1].stars}</span>
                                <div className="starsA" ref={myRef}></div>
                                <hr />
                                <span className='assessment'>{companyjson[id - 1].comments.length} Dəyərləndirmə &gt;</span>
                            </div>
                        </div>
                        <div className='AdvertPageContentL3'>
                            <button onClick={e => OpenPhonePopUp()} className='makePhoneCall'>
                                <IoCall color='white' size={25} onClick={e => OpenPhonePopUp()} className='makePhoneCall' />
                                Zəng et
                            </button>
                            {
                                openPhonePopUp &&
                                <div className='popUpSign' ref={popupRefSign}>
                                    <span>{companyjson[id - 1].phone}</span>
                                </div>
                            }

                        </div>
                    </div>
                    <hr />
                    <div className='AdvertPageCont2' style={{ whiteSpace: "pre-wrap" }}>
                        <span className='AdvertPageCont2Desc' style={{ whiteSpace: "pre-wrap" }}>
                            {companyjson[id - 1].work_description.replace(/\\n/g, '\n')}
                        </span>
                    </div>

                </div>
                <div className='AdvertPageContentR'>
                    <ImageGallery
                        items={images}
                        autoPlay={true}
                        showPlayButton={false}
                        slideInterval={5000}
                        ref={imageGalleryRef}
                        onClick={onClickHandler}
                        thumbnailWidth={100}
                    />
                </div>
            </div>
            {/* {openPhonePopUp &&
                <div className='openPhonePopUp' ref={popupRefSign}>
                    <div>
                        <span>Əlaqə nomrəsi:</span>
                        {mobile ? (
                            <a href={`tel:${companyjson[id - 1].phone}`}>{companyjson[id - 1].phone}</a>
                        ) : (
                            <span>{companyjson[id - 1].phone}</span>
                        )}
                    </div>
                </div>
            } */}
        </div>
    )
}

export default AdvertPage