import React from 'react'
import AdvertPage from '../Components/AdvertPage/AdvertPage'

const AdvertisementPage = () => {
  return (
    <>
      <AdvertPage />
    </>
  )
}

export default AdvertisementPage